<template>
    <div id="map" style="width: 100%; height: 800px;"></div>
  </template>
  
  <script>
  export default {
    mounted() {
      // 初始化百度地图
      const map = new BMap.Map("map");
      
      // 设置地图中心点和缩放级别（以绍兴市为例）
      const point = new BMap.Point(120.582112, 30.036025);
      map.centerAndZoom(point, 10);
      
      // 添加边界覆盖物（显示绍兴市边界）
      const bdary = new BMap.Boundary();
      bdary.get("绍兴市", function(rs) {
        const count = rs.boundaries.length;
        for (let i = 0; i < count; i++) {
          const ply = new BMap.Polygon(rs.boundaries[i], {strokeWeight: 2, strokeColor: "#ff0000", fillColor: ""});
          map.addOverlay(ply);
        }
      });
       
      // 启用地图缩放控件
      map.addControl(new BMap.NavigationControl()); 
      map.enableScrollWheelZoom(); // 启用鼠标滚轮缩放
    }
  }
  </script>
  
  <style>
  #map {
    height: 100vh;
    width: 100%;
  }
  </style>
  